import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="ABout" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Everywhere I go, I always bring my perspective as a designer to collaborate, learn, and create.
          </h2>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Tokyo CS50 Meetup Group</figcaption>
          </figure>
          <h3 id="dynamic-styles">About me</h3>
          <p>
            I've worked as a graphic designer in a few different industries before deciding to travel and teach English in Japan. There I used the same creative problem solving skills to help students. After that moved to Tokyo and got back into design while learning some web development along the way.          </p>
          <p>
            Everywhere I go, I always bring my perspective as a designer to collaborate, learn, and create. Design thinking comes up time and time again, be it in teaching, marketing or development. It’s all about interacting with people and how we can reach a specific goal.          </p>
          <p>
            I enjoy the outdoors and photography, but after working closely with developers, I've recently spent my down time learning programming because I want to bring my ideas to life!
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "tokyo-cs-group.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
